module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: '2B2S', // Navigation and Site Title
  titleAlt: '2B2S', // Title for JSONLD
  description: "Plateforme universitaire d'analyses bio-informatiques RNA-Seq, métagénomiques et phylogéniques",
  url: 'https://2b2s.univ-fcomte.fr', // Domain of your site. No trailing slash!
  siteUrl: 'https://2b2s.univ-fcomte.fr', // url + pathPrefix
  siteLanguage: 'fr', // Language Tag on <html> element
  logo: 'static/logo/logo.png', // Used for SEO
  banner: 'static/logo/banner.png',
  // JSONLD / Manifest
  favicon: 'static/logo/favicon.png', // Used for manifest favicon generation
  shortName: '2B2S', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Alban Atchon', // Author for schemaORGJSONLD
  themeColor: '#229ec4',
  backgroundColor: '#041031',
};
